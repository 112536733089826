import React, { Component } from 'react'
import { Link } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol, toast, ToastContainer } from 'mdbreact'
import { Zoom } from 'react-toastify'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'

const validationSchema = Yup.object().shape({
  country: Yup.string()
    .min(2, 'Name muss mindestens 2 Zeichen lang sein')
    .required('Bitte Land angeben'),  
  email: Yup.string()
    .email('Feld wurde nicht im erwarteten Format ausgefüllt')
    .required('Bitte E-Mail-Adresse angeben'),
  consentsubscribe: Yup.boolean().oneOf([true], 'Ihre Zustimmung ist erforderlich'),
  consentstore: Yup.boolean().oneOf([true], 'Ihre Zustimmung ist erforderlich'),
})

const formUrl = 'https://script.google.com/macros/s/AKfycbw_01f0mCi90LloK9Pjs66einp7n7dcLWAcEjLNNc_YEFuhjMcNv8g7pktjcsnJEh9KCw/exec'

const messageConfirm = () => {
  return toast.info("Danke, dass Sie uns kontaktiert haben. Wir werden uns in Kürze bei Ihnen melden!", { closeButton: false, position: toast.POSITION.TOP_CENTER, transition: Zoom })
}

class formTraining extends Component {
  constructor(props, ...args) {
    super(props, ...args)
    this.state = {
      callback: 'not fired',
      value: null,
      pageUrl: null,
      load: false,
      expired: 'false',
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ 
        load: true,
        pageUrl: location.href 
      })
    })
  }

  handleChange = (value) => {
    this.setState({ value })
  }

  asyncScriptOnLoad = () => {
    this.setState({ callback: 'called!' })
  }

  render() {
    const { value, callback, load, expired } = this.state || {}

    return (
      <MDBContainer>
        <MDBRow className="justify-content-center">
          <MDBCol lg="9" md="12" className="md-0 mb-5">
            <Formik
              initialValues={{
                course1: '',
                course2: '',
                course3: '',
                name: '',
                company: '',
                street: '',
                postcode: '',
                location: '',              
                country: '',
                phone: '',
                email: '',
                billingstreet: '',
                billingpostcode: '',
                billinglocation: '',
                message: '',
                consentsubscribe: false,
                consentstore: false,
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                /* alert('Form is validated! Submitting the form...') */
                let formData = {
                  Course1: values.course1,
                  Course2: values.course2,
                  Course3: values.course3,
                  Name: values.name,
                  Company: values.company,
                  Street: values.street,
                  Postcode: values.postcode,
                  Location: values.location,
                  Country: values.location,
                  Phone: values.phone,
                  Email: values.email,
                  Billingstreet: values.billingstreet,
                  Billingpostcode: values.billingpostcode,
                  Billinglocation:values.billinglocation,
                  Message: values.message,
                  Consentsubscribe: values.consentsubscribe,
                  Consentstore: values.consentstore,
                }
                try {
                  const response = axios({
                    method: 'get',
                    url: `${formUrl}?timestamp=&url=${encodeURIComponent(this.state.pageUrl)}&course1=${encodeURIComponent(values.course1)}&course2=${encodeURIComponent(values.course2)}&course3=${encodeURIComponent(values.course3)}&name=${encodeURIComponent(values.name)}&company=${encodeURIComponent(values.company)}&street=${encodeURIComponent(values.street)}&postcode=${encodeURIComponent(values.postcode)}&location=${encodeURIComponent(values.location)}&country=${encodeURIComponent(values.country)}&phone=${encodeURIComponent(values.phone)}&email=${encodeURIComponent(values.email)}&billingstreet=${encodeURIComponent(values.billingstreet)}&billingpostcode=${encodeURIComponent(values.billingpostcode)}&billinglocation=${encodeURIComponent(values.billinglocation)}&comments=${encodeURIComponent(values.message)}&subscribe=${encodeURIComponent(values.consentsubscribe)}&store=${encodeURIComponent(values.consentstore)}`
                  })
                  //console.log(response)
                  this.setState({ values: '', expired: 'true' })
                  resetForm()
                  messageConfirm()
                } catch (e) {
                  console.log(`Axios request failed: ${e}`)
                }
                setSubmitting(false)
              }}
            >
              {({ touched, errors, values, isSubmitting }) => (
                <Form
                  name="contact"
                  method="post"
                  action=""
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="training-registration" />

                  <section className="form-section rounded">
                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left mb-3 mt-3">Online-Kursanmeldung</h2>
                          <hr />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol lg="12">
                        <div className="pb-4 grey-text font-w-400 text-medium">
                          <strong>Entwerfen, Produzieren und Verteilen von Dokumenten mit DoXite / Customizing DoXite</strong>
                          <br /><br />
                          Preis zzgl. MwSt.: 2.950,-- €
                      </div>                        
                        <div className="mb-4 grey-text font-w-600 text-medium">
                         <label htmlFor="course1">Kurs-Nr: DX-01-DB</label>
                         <Field
                            type="course1"
                            name="course1"
                            id="course1"
                            as="select"
                            value={values.course1}
                            placeholder="Please select an option"
                            className={`form-control ${ touched.course1 && errors.course1 ? 'is-invalid' : '' }`}
                          >
                            <option value="" label="Bitte wählen Sie einen Termin"> {' '} </option>
                            <option value="04.03 - 08.03.2024" label="04.03 - 08.03.2024" > 04.03. - 08.03.2024 </option>
                            <option value="03.06 - 07.06.2024" label="03.06 - 07.06.2024" > 03.06. - 07.06.2024 </option>
                            <option value="15.07 - 19.07.2024" label="15.07 - 19.07.2024" > 15.07. - 19.07.2024 </option>
                            <option value="09.09 - 13.09.2024" label="09.09 - 13.09.2024" > 09.09. - 13.09.2024 </option>
                            <option value="11.11 - 15.11.2024" label="11.11 - 15.11.2024" > 11.11. - 15.11.2024 </option>
                          </Field>
                          <ErrorMessage component="div" name="course1" className="invalid-feedback" />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <hr />

                    <MDBRow>
                      <MDBCol lg="12">
                        <div className="py-4 grey-text font-w-400 text-medium">
                        <strong>DoXite-Projekte mit Python-Skripten ergänzen</strong>
                          <br /><br />
                          Preis zzgl. MwSt.: 2.360,-- €
                      </div>                        
                        <div className="mb-4 grey-text font-w-600 text-medium">
                         <label htmlFor="course2">Kurs-Nr: DX-02-PY</label>
                         <Field
                            type="course2"
                            name="course2"
                            id="course2"
                            as="select"
                            value={values.course2}
                            placeholder="Please select an option"
                            className={`form-control ${ touched.course2 && errors.course2 ? 'is-invalid' : '' }`}
                          >
                            <option value="" label="Bitte wählen Sie einen Termin"> {' '} </option>
                            <option value="11.03 - 14.03.2024" label="11.03 - 14.03.2024" > 11.03. - 14.03.2024 </option>
                            <option value="22.07 - 25.07.2024" label="22.07 - 25.07.2024" > 22.07. - 25.07.2024 </option>
                            <option value="18.11 - 21.11.2024" label="18.11 - 21.11.2024" > 18.11. - 21.11.2024 </option>
                          </Field>
                          <ErrorMessage component="div" name="course2" className="invalid-feedback" />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <hr />

                    <MDBRow>
                      <MDBCol lg="12">
                        <div className="py-4 grey-text font-w-400 text-medium">
                        <strong>Grundlagen der Verarbeitung von PDF- und OTF-Daten mit DoXite METACreator und METAPrint</strong>
                          <br /><br />
                          Preis zzgl. MwSt.: 1.770,-- €
                      </div>                        
                        <div className="mb-4 grey-text font-w-600 text-medium">
                         <label htmlFor="course3">Kurs-Nr: DX-03-META</label>
                         <Field
                            type="course3"
                            name="course3"
                            id="course3"
                            as="select"
                            value={values.course3}
                            placeholder="Please select an option"
                            className={`form-control ${ touched.course3 && errors.course3 ? 'is-invalid' : '' }`}
                          >
                            <option value="" label="Bitte wählen Sie einen Termin"> {' '} </option>
                            <option value="10.06 - 12.06.2024" label="10.06 - 12.06.2024" > 10.06. - 12.06.2024 </option>
                            <option value="16.09 - 18.09.2024" label="16.09 - 18.09.2024" > 16.09. - 18.09.2024 </option>
                          </Field>
                          <ErrorMessage component="div" name="course3" className="invalid-feedback" />
                        </div>
                      </MDBCol>
                    </MDBRow>
                  </section>

                  <section className="form-section rounded">
                    <MDBRow>
                        <MDBCol md="12">
                          <div className="mb-4 grey-text font-w-600 text-medium">
                            <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left mb-3 mt-3">Untermensinformationen</h2>
                            <hr />
                          </div>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="name">
                            Name
                          </label>
                          <Field
                            type="name"
                            name="name"
                            aria-label="name"
                            value={values.name}
                            placeholder="Vollständiger Name"
                            className={`form-control ${ touched.name && errors.name ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage component="div" name="name" className="invalid-feedback" />
                        </div>
                      </MDBCol>

                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="company">
                            Firma
                          </label>
                          <Field
                            type="company"
                            name="company"
                            aria-label="company"
                            value={values.company}
                            placeholder="Firmenname"
                            className={`form-control ${ touched.company && errors.company ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage component="div" name="company" className="invalid-feedback" />
                        </div>
                      </MDBCol>                
                    </MDBRow>
                    
                    <MDBRow>
                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="street">
                            Straße
                          </label>
                          <Field
                            type="street"
                            name="street"
                            aria-label="street"
                            value={values.street}
                            placeholder="Straße"
                            className={`form-control ${ touched.street && errors.street ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage component="div" name="street" className="invalid-feedback" />
                        </div>
                      </MDBCol>

                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="postcode">
                            PLZ
                          </label>
                          <Field
                            type="postcode"
                            name="postcode"
                            aria-label="postcode"
                            value={values.postcode}
                            placeholder="Postleitzahl"
                            className={`form-control ${ touched.postcode && errors.postcode ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage component="div" name="postcode" className="invalid-feedback" />
                        </div>
                      </MDBCol>                
                    </MDBRow>

                    <MDBRow>
                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="location">
                            Ort
                          </label>
                          <Field
                            type="location"
                            name="location"
                            aria-label="location"
                            value={values.location}
                            placeholder="Firmenstandort"
                            className={`form-control ${ touched.location && errors.location ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage component="div" name="location" className="invalid-feedback" />
                        </div>
                      </MDBCol>

                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="country">
                            Land <span style={{ color: 'red' }}>*</span>
                          </label>
                          <Field
                            type="country"
                            name="country"
                            aria-label="country"
                            value={values.country}
                            placeholder="Land"
                            className={`form-control ${ touched.country && errors.country ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage component="div" name="country" className="invalid-feedback" />
                        </div>
                      </MDBCol>                
                    </MDBRow>                    

                    <MDBRow>
                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="phone">
                            Telefon
                          </label>
                          <Field
                            type="phone"
                            name="phone"
                            aria-label="phone"
                            value={values.phone}
                            placeholder="Telefonnummer"
                            className={`form-control ${ touched.phone && errors.phone ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage component="div" name="phone" className="invalid-feedback" />
                        </div>
                      </MDBCol>

                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="email">
                            Email <span style={{ color: 'red' }}>*</span>
                          </label>
                          <Field
                            type="email"
                            name="email"
                            aria-label="email"
                            value={values.email}
                            placeholder="Firmen-E-Mail"
                            className={`form-control ${ touched.email && errors.email ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage component="div" name="email" className="invalid-feedback" />
                        </div>
                      </MDBCol>                
                    </MDBRow> 
                  </section>

                  <section className="form-section rounded">
                    <MDBRow>
                        <MDBCol md="12">
                          <div className="mb-4 grey-text font-w-600 text-medium">
                            <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left mb-3 mt-3">Rechnungsadresse, wenn von oben abweichend</h2>
                            <hr />
                          </div>
                        </MDBCol>
                    </MDBRow>
                  
                    <MDBRow>
                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="billingstreet">
                            Straße
                          </label>
                          <Field
                            type="billingstreet"
                            name="billingstreet"
                            aria-label="billingstreet"
                            value={values.billingstreet}
                            placeholder="Straße, wenn von oben abweichend"
                            className={`form-control ${ touched.billingstreet && errors.billingstreet ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage component="div" name="billingstreet" className="invalid-feedback" />
                        </div>
                      </MDBCol>

                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="billingpostcode">
                            PLZ
                          </label>
                          <Field
                            type="billingpostcode"
                            name="billingpostcode"
                            aria-label="billingpostcode"
                            value={values.billingpostcode}
                            placeholder="PLZ, wenn von oben abweichend"
                            className={`form-control ${ touched.billingpostcode && errors.billingpostcode ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage component="div" name="billingpostcode" className="invalid-feedback" />
                        </div>
                      </MDBCol>                
                    </MDBRow>

                    <MDBRow>
                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="billinglocation">
                            Ort
                          </label>
                          <Field
                            type="billinglocation"
                            name="billinglocation"
                            aria-label="billinglocation"
                            value={values.billinglocation}
                            placeholder="Ort, wenn von oben abweichend"
                            className={`form-control ${ touched.billinglocation && errors.billinglocation ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage component="div" name="billinglocation" className="invalid-feedback" />
                        </div>
                      </MDBCol>        
                    </MDBRow>                   
                 </section> 


                 <section className="form-section rounded">
                  <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left mb-3 mt-3">Anmerkungen</h2>
                          <hr />
                        </div>
                      </MDBCol>
                  </MDBRow>                


                 <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="message">Text eingeben</label>
                        <Field
                          type="textarea"
                          component="textarea"
                          name="message"
                          aria-label="message"
                          value={values.message}
                          placeholder="Mitteilung eingeben"
                          className={`form-control ${ touched.message && errors.message ? 'is-invalid' : '' }`}
                        />
                        <ErrorMessage component="div" name="message" className="invalid-feedback" />
                      </div>
                    </MDBCol>
                  </MDBRow> 
                </section>               


                  <hr /> 

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mt-3 mb-4 grey-text font-w-400 text-small">
                        Mit Ankreuzen der Checkbox und Anklicken des Buttons „Absenden“ willigen Sie ein, dass die DETEC Software GmbH Ihre im Formular angegebene E-Mailadresse verarbeiten darf, um im Rahmen eines Newsletter über Produkte, Dienstleistungen, Neuigkeiten und Veranstaltungen der DETEC Software GmbH zu informieren. Die Rechtsgrundlage für die Verarbeitung ist Art. 6 Abs. 1 lit a) DSGVO. Ihre hier erteilte Einwilligung ist freiwillig und kann jederzeit und kostenlos für die Zukunft widerrufen werden.
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="form-group form-check mt-3 mb-2 grey-text font-w-400 text-small">
                        <Field
                          type="checkbox"
                          id="consentsubscribe"
                          name="consentsubscribe"
                          aria-label="consentsubscribe"
                          className={`form-check-input ${ touched.consentsubscribe && errors.consentsubscribe ? 'is-invalid' : '' }`}
                        />
                        <label htmlFor="consentsubscribe"> Ja, ich bin einverstanden. <span style={{ color: 'red' }}>*</span></label>
                        <ErrorMessage name="consentsubscribe" component="div" className="invalid-feedback font-w-700 text-medium-small" />                        
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mt-3 mb-4 grey-text font-w-400 text-small">
                      Mit Ankreuzen der Checkbox und Anklicken des Buttons „Absenden“ willigen Sie ein, dass die DETEC Software GmbH Ihre im Rahmen des Kontaktformulars angegebenen Daten auf ihren Servern und IT-Systemen speichern und zur Beantwortung Ihrer Anfrage verarbeiten darf. Die Rechtsgrundlage für die Verarbeitung ist Art. 6 Abs. 1 lit. a) DSGVO. Ihre hier erteilte Einwilligung ist freiwillig und kann jederzeit und kostenlos für die Zukunft widerrufen werden. Die ausführliche Datenschutzerklärung inklusive Ihrer Rechte und Widerrufsmöglichkeiten finden Sie unter nachfolgendem.
                      <br /><br />
                      <Link to="/datenschutzerklaerung/" className="effect">Datenschutzerklärung</Link>
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="form-group form-check mt-3 mb-2 grey-text font-w-400 text-small">
                        <Field
                          type="checkbox"
                          id="consentstore"
                          name="consentstore"
                          aria-label="consentstore"
                          className={`form-check-input ${ touched.consentstore && errors.consentstore ? 'is-invalid' : '' }`}
                        />
                        <label htmlFor="consentstore"> Ja, ich bin einverstanden. <span style={{ color: 'red' }}>*</span></label>
                        <ErrorMessage name="consentstore" component="div" className="invalid-feedback font-w-700 text-medium-small" />
                      </div>
                    </MDBCol>
                  </MDBRow> 

                  <MDBRow>
                    <MDBCol md="12" className="mb-4">
                      <div style={{ paddingTop: '1rem' }}>
                        <button
                          type="submit"
                          className="mt-4 btn btn-medium btn-unicom"
                          style={{ display: 'block' }}
                          disabled={isSubmitting ? true : false}
                        >
                          {isSubmitting ? 'Please wait...' : 'Zahlungsplichtig buchen'}
                        </button>
                        <ToastContainer
                          transition={Zoom}
                          hideProgressBar={true}
                          newestOnTop={true}
                          autoClose={5000}
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                </Form>
              )}
            </Formik>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    )
  }
}

export default formTraining