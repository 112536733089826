import React from 'react'
import { MDBRow, MDBCol } from 'mdbreact'
import { GoogleMap, LoadScript } from '@react-google-maps/api'
import { Marker } from '@react-google-maps/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt, faEnvelope, faMapLocationDot, } from '@fortawesome/pro-solid-svg-icons'

class ColumnAlternatingOffices extends React.Component {
  render() {
    return (
      <MDBRow className={`mx-1 alt-area ${ this.props.placement === 'right' ? 'flex-row-reverse' : '' }`} >
        <MDBCol lg="6" className="alt-right">
          <div id="map-container" className="rounded z-depth-1-half map-container" style={{ height: '575px' }} >
            <LoadScript id="script-loader" googleMapsApiKey="AIzaSyCpXjW8NbD3JZOylLfE3vYEBj1spgmovhU" >
              <GoogleMap
                id="example-map"
                mapContainerStyle={{ height: '575px', width: '100%', }}
                zoom={15}
                center={{ lat: parseFloat(this.props.lat), lng: parseFloat(this.props.lng), }}
              >
                <Marker
                  onLoad={marker => {}}
                  position={{ lat: parseFloat(this.props.lat), lng: parseFloat(this.props.lng), }}
                />
              </GoogleMap>
            </LoadScript>
          </div>
        </MDBCol>

        <MDBCol lg="6" className="alt-left bg-color">
          <div className="alt-content">
            <h3 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-medium">
              {this.props.office}
            </h3>

            <p className="font-w-400 text-large" style={{ whiteSpace: 'pre-wrap' }} >{this.props.address}</p>

            <ul className="fa-ul ml-0 pt-2">
             {(this.props.tel || this.props.email) && (
             <>
                <li>
                    <span className="fa-li position-static">
                      <FontAwesomeIcon icon={faPhoneAlt} className="mr-3 ico-color" />{this.props.tel}
                    </span>
                </li>
                <li>
                    <span className="fa-li position-static">
                      <FontAwesomeIcon icon={faEnvelope} className="mr-3 ico-color" />
                      <a href={'mailto:' + this.props.email} className="effect" rel="noopener" target="_blank">{this.props.email}</a> 	
                    </span>
                </li>
             </>
             )}
                <li>
                    <span className="fa-li position-static">
                      <FontAwesomeIcon icon={faMapLocationDot} className="mr-3 ico-color" /><a className="effect" rel="noopener" href={'https://www.google.com/maps/dir/Current+Location/' + this.props.lat + ',' + this.props.lng} target="_blank">Wegbeschreibung</a>
                    </span>
                </li>
           </ul>

          </div>
        </MDBCol>
      </MDBRow>
    )
  }
}

export default ColumnAlternatingOffices
