import React from 'react'
import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBContainer, MDBTestimonial } from 'mdbreact'

class CarouselQuote extends React.Component {
  render() {
    return (
    <section className="blockback">
      <MDBContainer>
        <MDBCarousel
          activeItem={1}
          length={this.props.data.length}
          showControls={false}
          showIndicators={(this.props.data.length > 1)}
          interval={9000}
          className="carousel-height"
          style={{ minHeight: '450px', maxHeight: '450px' }}
        >
          <MDBCarouselInner>
            {this.props.data.map((quote, index) => (
            <MDBCarouselItem itemId={index + 1} key={index}>
              <MDBTestimonial>
                <div className="blockquote-wrapper">
                  <div className="blockquote">
                    {quote.title &&
                      <h2>{quote.title}</h2>
                    }

                  <div className="strapline">{quote.strapline}</div>
                  <div className="author">{quote.author}</div>

                  </div>
                </div>
              </MDBTestimonial>
            </MDBCarouselItem>
            ))}
          </MDBCarouselInner>
        </MDBCarousel>
      </MDBContainer>
    </section>
    )
  }
}

export default CarouselQuote